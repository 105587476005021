<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>User Payment Method</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">User Payment Method</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="overlay" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-header">
              <h3 class="card-title">Detail</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'UserPaymentMethodList'}">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped">
                <tbody>

                  <tr>
                    <td><b>ID</b></td>
                    <td>{{ value.id }}</td>
                  </tr>

                  <tr>
                    <td><b>User-Id</b></td>
                    <td>{{ value.userId }}</td>
                  </tr>

                  <tr>
                    <td><b>Account-Id</b></td>
                    <td>{{ value.accountId }}</td>
                  </tr>

                  <tr>
                    <td><b>Gateway-Id</b></td>
                    <td>{{ value.gatewayId }}</td>
                  </tr>

                  <tr>
                    <td><b>Priority</b></td>
                    <td>{{ value.priority }}</td>
                  </tr>

                  <tr>
                    <td><b>Status</b></td>
                    <td>{{ value.status }}</td>
                  </tr>

                  <tr>
                    <td><b>Application-Id</b></td>
                    <td>{{ value.appId }}</td>
                  </tr>

                  <tr>
                    <td><b>Gateway Parameters</b></td>
                    <td>{{ value.gatewayParams }}</td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserPaymentMethodShow",
  data: () => ({
    value: {

    },
    pre: {

    },
    is_loading: false
  }),
  mounted: function () {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.is_loading = true;
      this.params = this.$route.params;

      await this.getDetail(this.params.id).then(detail => {
        this.value = detail;
      });

      this.is_loading = false;
    },
    getDetail: function (id) {
      let _this = this;
      return new Promise(resolve => {
        let api_url = "/user_payment_method/" + id;
        _this.is_loading = true;
        axios.get(api_url)
          .then(response => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          })
          .finally(() => {
            _this.is_loading = false;
          });
      });
    },
  }
}
</script>
